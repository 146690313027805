<template>
  <div class="vendor-card">

    <div class="vendor-card-cover">
      <router-link :to="link">
        <v-img
          :alt="name"
          :src="vendorCover"
          max-height="140"
          @error="useDefaultCover"
        />
      </router-link>
    </div>

    <div class="vendor-card-image">
      <router-link :to="link">
        <img
          :alt="name"
          :src="vendorLogo"
          @error="useDefaultLogo"
        />
      </router-link>
    </div>

    <div class="vendor-card-details">
      <div class="vendor-card-info text-center">
        <v-card-text>
          <router-link :to="link">
            <h3 class="vendor-name text-center mb-2 ">
              <a class="text-decoration-none" href="#">{{ name }}</a>
            </h3>
          </router-link>
          <p v-if="address" class="vendor-address"> {{ address }} </p>
          <p class="vendor-contact">
            <span v-if="email"><strong>Email</strong>: {{ email }} </span> <br />
            <span v-if="phone"><strong>Phone</strong>: {{ phone }} </span>
          </p>
        </v-card-text>
      </div>
      <div class="vendor-card-action">
        <router-link :to="link">
          <v-btn color="secondary" outlined block>Visit Store</v-btn>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import emptyShopLogo from '@/assets/images/emptyShopLogo.jpg';
import emptyShopCover from '@/assets/images/emptyShopCover.png';

export default {
  name: 'VendorSimple',
  props: {
    coverPhoto: {
      type: String
    },
    logo: {
      type: String,
    },
    name: {
      type: String,
    },
    address: {
      type: String,
    },
    email: {
      type: String
    },
    phone: {
      type: String,
    },
    link: {
      type: [Object, String]
    }
  },

  data() {
    return {
      vendorLogo: this.logo || emptyShopLogo,
      vendorCover: this.coverPhoto || emptyShopCover
    };
  },

  methods: {
    useDefaultLogo() {
      this.vendorLogo = emptyShopLogo;
    },

    useDefaultCover() {
      this.vendorCover = emptyShopCover;
    }
  }
};
</script>

<style lang="scss">
.vendor-card {
  background-color: white;
  transition: all 0.4s ease-in-out;

  &:hover {
    box-shadow: 5px 5px 5px -2px #ccc;
  }

  p {
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  position: relative;

  img {
    max-width: 100%;
  }

  &-image {
    transform: translate(0, -50px);
    display: flex;
    justify-content: center;
    text-align: center;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }

  &-details {
    margin-top: -50px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &-action {
    margin-top: -10px;
    width: 100%;

    .btn.vendor-card-visit {
      display: inline-block;
      width: 100%;
      text-align: center;
      background-color: #006AFF;
      color: #ffffff;
      padding: 10px 25px;
      text-decoration: none;
    }
  }
}
</style>
